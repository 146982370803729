import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import "./WelcomePage.css";
import LogoImg from "../../assets/png/logoImg.png";
import { ReactComponent as UserIcon } from "../../../src/assets/svg/UserIcon.svg";
import { ReactComponent as OtpIcon } from "../../../src/assets/svg/OtpIcon.svg";
import { ReactComponent as LoginIcon } from "../../../src/assets/svg/LoginIcon.svg";
import { Link, json, useNavigate } from "react-router-dom";
import { fetchAllData, fetchSingleData } from "../../utils/fetchData";
import { useQuery } from "react-query";
import LoadingOverlay from "react-loading-overlay-ts";
import { toast } from "react-toastify";
import { ProgressSpinner } from "primereact/progressspinner";
import CustomCloseIcon from "../../utils/CustomCloseIcon";
import NFIHIcon from "../../assets/png/NFIH-logo.png"

function WelcomePage() {
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [contact, setContact] = useState("");
  const [error, setError] = useState("");
  const [timer, setTimer] = useState(0);
  const [isResendDisabled, setIsResendDisabled] = useState(false);

  //const [resData, setresData] = useState(null);
  // OTP field code
  const [otp, setOtp] = useState(Array(6).fill(""));
  const otpRef = useRef([]);
  const navigate = useNavigate();

  const otpChange = (index, value) => {
    if (/^[0-9]$/.test(value) || value === "") {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // code to move to the next field of the otp input
      if (value === "" && index > 0) {
        otpRef.current[index - 1].focus(); // Move to the previous field
      } else if (value !== "" && index < otp.length - 1) {
        otpRef.current[index + 1].focus(); // Move to the next field
      }
    } else if (value === "" && index > 0) {
      // Handle backspace key press
      otpRef.current[index - 1].focus(); // Move to the previous field
    }
  };

  const handleChange = (e) => {
    setContact(e.target.value);
  };

  useEffect(() => {
    if (timer > 0) {
      const countdown = setTimeout(() => setTimer(timer - 1), 1000);
      return () => clearTimeout(countdown);
    } else {
      setIsResendDisabled(false);
    }
  }, [timer]);

  //validation for ph.no and email
  const validateForm = () => {
    if (!contact) {
      setError("Please enter Email ");
      return false;
    } else if (!/\S+@\S+\.\S+/.test(contact)) {
      setError("Invalid email address");
      return false;
    }
    // } else if (!/^\d{10}$/.test(contact) && !/\S+@\S+\.\S+/.test(contact)) {
    //   setError("Invalid email/ph.number");
    //   return false;
    // }

    setError("");
    return true;
  };

  //main page verify button handler
  const verifyHandler = async (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsApiPending(true);
    const formData = {
      contact,
    };
    console.log(formData.contact); // form data

    if (validateForm()) {
      try {
        const response = await axios.post(`${apiURL}/v1/auth/signup`, {
          email: formData.contact,
        });
        console.log(response);
        const responseData = response?.data;
        const token = responseData.body[0].token;
        console.log(token);
        localStorage.setItem("token", token);

        console.log(responseData);
        setShowOtpModal(true);
        // setIsResendDisabled(true)
      } catch (error) {
        console.error("Error in the code:", error.message);
        toast.error("Error Sending OTP!");
      } finally {
        setIsApiPending(false);
      }
    }
  };

  const handleKeyDown = (e, index) => {
    // Check if Enter key is pressed and if it's the last OTP field
    if (e.key === "Enter" && index === otp.length - 1) {
      otpSubmitHandler()
    }
  };
  

  const resendHandler = () => {
    verifyHandler();
    setIsResendDisabled(true);
    setTimer(30)
  };

  //otp modal submit button
  const otpSubmitHandler = async () => {
    const storedToken = localStorage.getItem("token");
    if (storedToken) {
      const headers = {
        Authorization: `Bearer ${storedToken}`,
        "Content-type": "application/json",
      };
      console.log(headers);
      const otpValue = otp.join("");
      try {
        const otpResponse = await axios.post(
          `${apiURL}/v1/auth/login`,
          { otp: otpValue },
          { headers }
        );
        console.log(otpResponse.data);
        localStorage.setItem(
          "userData",
          JSON.stringify(otpResponse?.data.body[0].user)
        );
        setShowOtpModal(false);
        navigate("/meetingrooms");
      } catch (error) {
        toast.error("Invalid OTP, Please try again");
        console.error("Error in OTP verification:", error.message);
      }
    }
  };
  const apiURL = process.env.REACT_APP_API_URL;
  const [welcomeData, setWelcomeData] = useState([]);
  const [logoImagePath, setLogoImagePath] = useState("");
  const [logoData, setLogoData] = useState([]);
  const welcomeEndpoint = "/v1/settings";
  const [isApiPending, setIsApiPending] = useState(false);
  const { data, isLoading } = useQuery(
    ["welcome", welcomeEndpoint],
    () => fetchAllData(welcomeEndpoint),
    {
      onSuccess: (data) => {
        setWelcomeData(data);
        if (data?.data && data?.data[0]?.image) {
          setLogoImagePath(data?.data[0]?.image);
        }
      },
    }
  );

  // image code
  // const welcomeLogoEndpoint = `v1/files/${logoImagePath}`
  // const {data, isLoading} = useQuery(['welcomeLogo',welcomeLogoEndpoint])
  console.log("logoImagePath", logoImagePath);
  const getLogoUrl = `${apiURL}/v1/files/${logoImagePath}`;
  console.log("logo API path", logoImagePath);
  const [logoImg, setLogoImg] = useState();

  const fetchDataAndLog = async () => {
    try {
      const response = await axios.get(getLogoUrl, {
        responseType: "arraybuffer",
      });
      const imageBlob = new Blob([response?.data], { type: "image/png" });
      const imageUrl = URL.createObjectURL(imageBlob);
      setLogoImg(imageUrl);
      console.log("response", response);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchDataAndLog();
  }, [logoImagePath]);

  //  logo image
  //  const welcomeLogoEndpoint = `v1/files/${logoImagePath}`
  //  console.log("path for image API",welcomeLogoEndpoint)
  //  const {data:dataLogo,isLoading:isLoadingLogo} = useQuery(['welcomeLogo',welcomeLogoEndpoint],() => fetchSingleData(welcomeEndpoint),
  //  {
  //    onSuccess : (dataLogo) =>{
  //     setLogoData(dataLogo);

  //    }
  //  });
  //  console.log("logo data for image",logoData)

  return (
    <>
      <LoadingOverlay active={isLoading} spinner text="Loading your content...">
        <div className="welcomeWrapper">
          <div className="welcomeImgDiv">
            <div className="incubatorTxt">
              <h3>
                {(welcomeData?.data && welcomeData?.data[0]?.appname) ||
                  "Incubator"}
              </h3>
            </div>
          </div>
          <div className="loginCredentailsWrapper">
            <div className="loginCredentails">
              {/* <img src={logoImg} alt="" /> */}
              <img src={NFIHIcon} />
              <h2>Welcome !</h2>
              <form action="" onSubmit={verifyHandler}>
                <div className="inputcontainer">
                  <UserIcon />
                  <input
                    type="text"
                    placeholder="Enter Your Email"
                    value={contact}
                    onChange={handleChange}
                  />
                </div>
                <div className="errorClass">
                  {error && <span>{error}</span>}
                </div>
                <button
                  type="submit"
                  className="submitBtnClass email-verify-button"
                  disabled={isApiPending}
                >
                  <span>Verify</span>
                  <div
                    style={{
                      display: "flex",
                      height: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {isApiPending && (
                      <ProgressSpinner
                        style={{ width: "25px", height: "25px" }}
                        strokeWidth="8"
                        animationDuration=".5s"
                      />
                    )}
                  </div>
                </button>
              </form>
            </div>
            {/* OTP modal window */}

            {showOtpModal && (
              <div className="otpModalWrapper">
                <div className="otpmodal">
                  <CustomCloseIcon  fill="#FF0000" onClick={() => setShowOtpModal(false)} />
                  <div className="otpIconClass">
                    <OtpIcon />
                  </div>
                  <div className="otpTitle">
                    <h2>Enter PassKey</h2>
                  </div>
                  <div className="otpFieldClass">
                    {otp.map((value, index) => (
                      <input
                        type="text"
                        name=""
                        id=""
                        key={index}
                        value={value}
                        onChange={(e) => otpChange(index, e.target.value)}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        ref={(inputRef) => (otpRef.current[index] = inputRef)}
                      />
                    ))}
                  </div>
                  <div className="otpBtnClass">
                    <button
                      className="verifyOtpBtn"
                      type="submit"
                      onClick={otpSubmitHandler}
                    >
                      Verify Passkey
                    </button>
                    {/* <button
                      className="submitBtn"
                      type="submit"
                      onClick={otpSubmitHandler}
                    >
                      Submit
                    </button> */}
                    <p style={{ display: "flex", justifyContent: "center",  }}>
                      {isResendDisabled ? (
                        <span
                        className="waitPassKey"
                        >
                          Passkey successfully sent! Request another in {timer}s
                        </span>
                      ) : (
                        <span
                        className="resendText"
                          onClick={resendHandler}
                        >
                          Resend Passkey
                        </span>
                      )}
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </LoadingOverlay>
    </>
  );
}

export default WelcomePage;
