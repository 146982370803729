import React, { useEffect, useState } from "react";
import TopNavBar from "../../components/TopNavBar";
import Sidebar from "../../components/Sidebar";
import "./BookingRequests.css";
import { useNavigate } from "react-router-dom";
import DetailsPage from "./DetailsPage";
import axios from "axios";
import { useQuery, useQueries } from "react-query";
import { fetchAllData, fetchSingleData } from "../../utils/fetchData";
import { ReactComponent as ChevronRight } from "../../assets/svg/ChevronRight.svg";
import { attachImagesToData } from "../../utils/fetchImagesForBookingRequests";
import Pagination from "../../components/navbarwidgets/Pagination";
import LoadingOverlay from "react-loading-overlay-ts";
import LoaderSpinner from "../../utils/LoaderSpinner";

function BookingRequests() {
  const apiURL = process.env.REACT_APP_API_URL;
  const monthShortForms = {
    January: "Jan",
    February: "Feb",
    March: "Mar",
    April: "Apr",
    May: "May",
    June: "Jun",
    July: "Jul",
    August: "Aug",
    September: "Sep",
    October: "Oct",
    November: "Nov",
    December: "Dec",
  };

  const [isContentFetched, setIsContentFetched] = useState(true);

  const [bookingType, setBookingType] = useState({
    type: "meeting",
    bookingRequestEndpoint: "/v1/meetingbookings/bookings",
    queryKey: "MeetingBookRequest",
  });
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [bookingData, setBookingData] = useState([]);
  const [selectedData, setSelectedData] = useState(null);
  const [loadedRendering, setLoadedRendering] = useState(true);
  const [refreshState, setRefreshState] = useState();

  const [isHidden, setIsHidden] = useState(false);
  const navigate = useNavigate();

  // pagination code
  const [meetingCurrentPage, setMeetingCurrentPage] = useState(1);
  const [meetingTotalPages, setMeetingTotalPages] = useState(0);
  const [coachCurrentPage, setCoachCurrentPage] = useState(1);
  const [coachTotalPages, setCoachTotalPages] = useState(0);
  const itemsPerPage = 10;
  //console.log(totalItems)

  // const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handleMeetingPrevious = () => {
    if (meetingCurrentPage > 1) {
      setMeetingCurrentPage(meetingCurrentPage - 1);
    }
  };

  const handleMeetingNext = () => {
    if (meetingCurrentPage < meetingTotalPages) {
      setMeetingCurrentPage(meetingCurrentPage + 1);
    }
  };

  const handleCoachPrevious = () => {
    if (coachCurrentPage > 1) {
      setCoachCurrentPage(coachCurrentPage - 1);
    }
  };

  const handleCoachNext = () => {
    if (coachCurrentPage < coachTotalPages) {
      setCoachCurrentPage(coachCurrentPage + 1);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsHidden(window.innerWidth > 450);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setLoadedRendering(false);

    return () => {
      setLoadedRendering(true);
    };
  }, []);
  //
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      weekday: "short",
      day: "2-digit",
      month: "long",
      year: "numeric",
    };
    return date.toLocaleDateString("en-US", options);
  };
  //for time
  const formatTime = (dateString) => {
    const date = new Date(dateString);
    const options = {
      hour: "numeric",
      minute: "numeric",
      hour12: true, // Set to false if you want 24-hour format
    };
    return date.toLocaleTimeString("en-US", options);
  };

  const [selectedBookingRequest, setSelectedBookingRequest] = useState();

  const [isReviewSelected, setIsReviewSelected] = useState();
  const [isTypeSwitching, setIsTypeSwitching] = useState(false);

  const { data, isLoading, isFetching, refetch } = useQuery(
    [bookingType.queryKey, bookingType.bookingRequestEndpoint],
    async () => {
      // Start loading when type is switched
      setIsTypeSwitching(true);

      const responseData = await fetchAllData(
        bookingType.bookingRequestEndpoint
      );
      const dataWithImages = await attachImagesToData(
        responseData["data"],
        bookingType.bookingRequestEndpoint
      );

      // Sort the data based on createdAt
      const sortedData = dataWithImages.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );

      setIsTypeSwitching(false);
      return sortedData;
    },
    {
      keepPreviousData: true,
      onSuccess: (sortedData) => {
        setBookingData(sortedData);

        // Calculate total pages for pagination
        const totalPages = Math.ceil(sortedData.length / itemsPerPage);
        if (bookingType.type === "meeting") {
          setMeetingTotalPages(totalPages);
        } else if (bookingType.type === "coach") {
          setCoachTotalPages(totalPages);
        }

        // Stop loader after data is successfully loaded
        setIsContentFetched(false);
      },
      onError: (error) => {
        console.error("Error attaching images to booking requests data", error);
        setIsContentFetched(false); // Stop loader in case of error
      },
    }
  );

  console.log("selectedBookingRequest", selectedBookingRequest);

  // Pagination data based on the selected booking type
  const startIndex =
    (bookingType.type === "meeting"
      ? meetingCurrentPage - 1
      : coachCurrentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const newData = bookingData.slice(startIndex, endIndex);

  const viewHandle = (id) => {
    const item = bookingData.filter((item) => item.id === id);
    setSelectedData(item);
    console.log("to get id of selected item", item);
    setDetailsOpen(true);
  };

  const mobileViewDate = (date) => {
    const formattedDate = formatDate(date);
    let month = formattedDate.split(" ")[1];
    const removedDay = formattedDate.split(" ").splice(1).join(" ");
    let shortDate = removedDay.replace(month, monthShortForms[month]);
    console.log("shortDateHere", shortDate);
    return shortDate;
  };

  return (
    <>
      {isLoading && (
        <div className="spinner-overlay">
          <LoaderSpinner />
        </div>
      )}

      <div className="navBarWrapper">
        <Sidebar />
        <div className="contentWrapper">
          <TopNavBar />
          <div className="bookingRequestWrapper">
            <div className="sortButtons">
              <button
                onClick={() => {
                  setIsContentFetched(true); // Trigger the loader
                  setBookingType({
                    type: "meeting",
                    bookingRequestEndpoint: "/v1/meetingbookings/bookings",
                    queryKey: "MeetingBookRequest",
                  });
                }}
                className={
                  bookingType.type === "meeting" ? "selectedButton" : ""
                }
                id="roomTypeId"
              >
                Meeting Room
              </button>
              <button
                onClick={() => {
                  setIsContentFetched(true); // Trigger the loader
                  setBookingType({
                    type: "coach",
                    bookingRequestEndpoint: "/v1/coachbookings/bookings",
                    queryKey: "CoachBookRequest",
                  });
                }}
                className={bookingType.type === "coach" ? "selectedButton" : ""}
                id="roomTypeId"
              >
                Coach
              </button>
            </div>

            <>
              <div className="bookingReqTableContainer">
                <table className="bookingRequestTable" id="bookingDetailsId">
                  <thead>
                    <tr>
                      <th>Customer Name</th>
                      <th>Date</th>
                      <th>Email Id</th>
                      <th>Time</th>
                      <th>Status</th>
                      <th>More Information</th>
                    </tr>
                  </thead>
                  <tbody>
                    {newData.map((item) => (
                      <tr key={item.id}>
                        <td>
                          {item.user.firstname + " " + item.user.lastname}
                        </td>
                        <td>{formatDate(item.createdAt)}</td>
                        <td>{item.user.email}</td>
                        <td>{formatTime(item.createdAt)}</td>
                        <td>
                          <button
                            className={getStatusClassName(item.visibility)}
                          >
                            {" "}
                            {item.visibility === "accepted"
                              ? "Accepted"
                              : item.visibility === "rejected"
                              ? "Rejected"
                              : "Pending"}
                          </button>
                        </td>
                        <td id="viewDetailsId">
                          <span
                            className="details-span"
                            onClick={() => {
                              viewHandle(item.id);
                            }}
                          >
                            view detail
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="pagWrapper marginRemove">
                  <Pagination
                    currentPage={
                      bookingType.type === "meeting"
                        ? meetingCurrentPage
                        : coachCurrentPage
                    }
                    totalPages={
                      bookingType.type === "meeting"
                        ? meetingTotalPages
                        : coachTotalPages
                    }
                    onPrevious={
                      bookingType.type === "meeting"
                        ? handleMeetingPrevious
                        : handleCoachPrevious
                    }
                    onNext={
                      bookingType.type === "meeting"
                        ? handleMeetingNext
                        : handleCoachNext
                    }
                  />
                </div>
              </div>

              <div className="mobileViewBookingsDetails">
                {newData.map((bookingDetail, index) => {
                  return (
                    <div className="bookingDeatilContainer" key={index}>
                      <div className="imageAndDetailsContainer">
                        <div className="imageContainer">
                          <img src={bookingDetail.imageUrl} alt="" />
                        </div>
                        <div className="detailsContainer">
                          <p>
                            {bookingDetail.user.firstname +
                              " " +
                              bookingDetail.user.lastname}
                          </p>
                          {console.log()}
                          <p>
                            {bookingType.type === "meeting"
                              ? "Meeting Room"
                              : "Coach"}
                          </p>
                          <p>{mobileViewDate(bookingDetail.createdAt)}</p>
                          <p>{formatTime(bookingDetail.createdAt)}</p>
                          <button
                            className={getStatusClassName(
                              bookingDetail.visibility
                            )}
                          >
                            {" "}
                            {bookingDetail.visibility === "accepted"
                              ? "Accepted"
                              : bookingDetail.visibility === "rejected"
                              ? "Rejected"
                              : "Pending"}
                          </button>
                        </div>
                      </div>
                      <div className="viewDetailsPageBtnContainer">
                        <div
                          className="placeHolderbtn"
                          onClick={() => viewHandle(bookingDetail.id)}
                        >
                          <ChevronRight />
                        </div>
                      </div>
                    </div>
                  );
                })}
                <div>
                  {bookingData.length
                    ? ""
                    : "There are no Bookings to be displayed"}
                </div>
                <div className="pagWrapper marginRemove">
                  <Pagination
                    currentPage={
                      bookingType.type === "meeting"
                        ? meetingCurrentPage
                        : coachCurrentPage
                    }
                    totalPages={
                      bookingType.type === "meeting"
                        ? meetingTotalPages
                        : coachTotalPages
                    }
                    onPrevious={
                      bookingType.type === "meeting"
                        ? handleMeetingPrevious
                        : handleCoachPrevious
                    }
                    onNext={
                      bookingType.type === "meeting"
                        ? handleMeetingNext
                        : handleCoachNext
                    }
                  />
                </div>
              </div>
            </>

            {detailsOpen && (
              <DetailsPage
                isOpen={detailsOpen}
                onClose={() => setDetailsOpen(false)}
                //onAccept={acceptHandle}
                detailsData={selectedData}
                service={bookingType}
                setRefreshState={setRefreshState}
                // bookId={id}
                refetch={refetch}
              />
            )}
          </div>
          {/*End of content  */}
        </div>
      </div>
    </>
  );
}
function getStatusClassName(visibility) {
  switch (visibility) {
    case "accepted":
      return "acceptedStatus";
    case "rejected":
      return "rejectedStatus";
    default:
      return "pendingStatus";
  }
}

export default BookingRequests;
