import React, { useEffect, useState } from "react";
import "./TopNavBar.css";

import NavbarLogo from "../../src/assets/png/navbarLogo.png";
import { ReactComponent as SearchIcon } from "../../src/assets/svg/SearchIcon.svg";
import NFIHIcon from "../assets/png/NFIH-logo.png"
import NotificationWidget from "./navbarwidgets/NotificationWidget";
//import ProfileWidget from './navbarwidgets/ProfileWidget';
import ProfileImg from "../../src/assets/png/ProfileImg.png";
import arrowIcon from "../../src/assets/png/arrowIcon.png";
//import ProfileWidget from "./navbarwidgets/ProfileWidget";
import LogOutWidget from "./navbarwidgets/LogOutWidget";
import HamburgerMenu from "./HamburgerMenu";
import axios from "axios";
import { useQuery } from "react-query";
import { fetchSingleData, fetchUserDetails } from "../utils/fetchData";
import { getUserSpecificSettings } from "../utils/uiSettingsOperations";
import { jwtDecode } from "jwt-decode";
const apiURL = process.env.REACT_APP_API_URL;


function TopNavBar() {

  const [userData, setUserData] = useState({});
  const [token, setToken] = useState(null);
  // code for logo
  const [logoUrl, setLogoUrl] = useState();
 // const [compName, setCompName] = useState("Incubator")

  useEffect(() =>{
    const fetchLogoUrl = async () =>{
      try{
        const settingsData = await getUserSpecificSettings();
        if(settingsData.length > 0){
          const logoImageUrl = settingsData[0]?.imageUrl;
          if(logoImageUrl){
            setLogoUrl(logoImageUrl);
          }
        }
        
      //  setCompName(settingsData[0]?.appname)
      }catch(error){
        console.log("Error in getting logo",error);
      }
    };
    fetchLogoUrl();
  },[]);

 // const [profileImg, setProfileImg] = useState(ProfileImg);
  const userEndpoint = '/v1/users';
  // code to decode token
 
    const storedToken = localStorage.getItem("token");
 
  const decodedToken = jwtDecode(storedToken);

  const {  } = useQuery(
    ['User', userEndpoint],
    () => fetchUserDetails(userEndpoint),
    {
      onSuccess: (data) => {
        setUserData(data)
      },
    }
  );
  
  const [isProfileClicked, setIsProfileClicked] = useState(false);
  const {data,isLoading} = useQuery({queryKey:'topNavBarName',queryFn:getUserSpecificSettings})

  // code for closing the modal
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.profileSection')) {
        setIsProfileClicked(false);
      }
    };

    if (isProfileClicked) {
      document.addEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isProfileClicked]);
//modal window code closed

  const handleProfile = () => {
    setIsProfileClicked(!isProfileClicked);
  };
  // const userData = JSON.parse(localStorage.getItem('userData'))

  return (
    <>
      <div className="topNavbarWrapper">
        <div className="logosection" id="companyLogoId">
          {/* <img src={logoUrl} alt="" /> */}
          <img src={NFIHIcon} />
        </div>
        <HamburgerMenu/>
        <div className="headerSearchSection">
          <div className="headerTxt">
            <h3 id="companyNameId">{data ? data[0]?.appname : "Incubator"}</h3>
          </div>
          {/* <div className="searchBar">
            <input type="search" name="" id="" placeholder="Search here" />
            <SearchIcon />
          </div> */}
        </div>
        <div className="notificationDiv" id="notificationId">
          <NotificationWidget />
        </div>
        {/* Profile section */}
        <div className="profileSection">
          <div className="profileWrapper">
            <h2 className="" id="adminNameId">{userData ? (userData?.firstname || "")+" "+(userData?.lastname || "") : "placeholder"}</h2>
            <div className="profileDetails" onClick={handleProfile} id="profileDetailsId">
              <img src={userData?.imageUrl} alt="" className="profImgClass" id="profileImgId"/>
              <span className="arrowIcon">
                <img src={arrowIcon} alt="" onClick={handleProfile} />
              </span>
            </div>
          </div>
          {isProfileClicked && <LogOutWidget setIsProfileClicked={setIsProfileClicked} onClose={handleProfile} />}
        </div>
        {/* Profile section */}
      </div>
    </>
  );
}

export default TopNavBar;
